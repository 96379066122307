import {
  SET_PACKAGES,
  CLEAR_FORM,
  SET_PACKAGE_FORM,
  SET_VENDORS,
  UPDATE_FORM,
  UPDATE_ITENARY_FORM,
  CLEAR_ITENARY_FORM,
  UPDATE_DEPARTURE_FORM,
  SET_PACKAGE_DEPARTURES,
  SET_PACKAGE_DEPARTURE_FORM,
  CLEAR_DEPARTURE_FORM,
  SET_PACKAGE_ITENARY_FORM,
  SET_SEARCH_PARAMETERS,
  CLEAR_SEARCH_PARAMETERS,
  SET_PACKAGE_REQUESTS,
} from '../mutation-types';

export default {
  /**
   * Set package regions.
   *
   * @param {Object} state
   * @param {Object} records
   */
  [SET_PACKAGES](state, records) {
    state.packages.data = records.data;
    state.packages.pagination.last_page = records.meta.last_page;
    state.packages.pagination.per_page = records.meta.per_page;
    state.packages.pagination.current_page = records.meta.current_page;
  },

  [SET_PACKAGE_REQUESTS](state, records) {
    state.packageRequests.data = records.data;
    state.packageRequests.pagination.last_page = records.meta.last_page;
    state.packageRequests.pagination.per_page = records.meta.per_page;
    state.packageRequests.pagination.current_page = records.meta.current_page;
  },

  /**
   * Set Vendors
   *
   * @param {Object} state
   * @param {Object} records
   */
  [SET_VENDORS](state, records) {
    state.vendors = records.data;
  },

  [CLEAR_FORM](state) {
    state.form = {
      name: "",
      slug: "",
      vendor_id: "",
      region_id: "",
      activity_id: "",
      departure_type: "",
      package_type: "",
      listing_type: "",
      price_per_person: "",
      guide_language: "",
      guiding_method: "",
      max_altitude: "",
      location_start_from: "",
      location_end_to: "",
      age: "",
      google_map: "",
      overview: "",
      highlights: "",
      includes: "",
      excludes: "",
      good_to_know: "",
      cancellation: "",
      accommodation: "",
      duration: "",
      discount_total_no: "",
      discount_percentage: "",
      is_sponsored: false,
      status: true,
      full_payment: false,
      images: [],
      meta_page_title: '',
      meta_keywords: '',
      meta_description: '',
      position: '',
      available_features: [],
      tag: "",
      no_follow: false,
      no_index: false,
    };

    state.geo = {
      available_features: [],
  };

    state.form_dropdown = {
      region_id: {},
      vendor_id: {},
      activity_id: {},
      departure_type: {},
      package_type: {},
      listing_type: {},
      tag:{}
    }
    state.package_images = [];
  },

  [SET_PACKAGE_FORM](state, records) {
    let formFeatures = [];
    if (records.available_features) {
        records.available_features.forEach((am, index) => {
            state.geo.available_features.push({ label: am.title, value: am.id });
            formFeatures.push(am.id);
        });
    }

    state.form = {
      name: records.name,
      slug: records.slug,
      vendor_id: records.vendor_id.id,
      region_id: records.region_id.id,
      activity_id: records.activity_id.id,
      departure_type: records.departure_type.id,
      package_type: records.package_type.id,
      listing_type: records.listing_type.id,
      price_per_person: records.price_per_person,
      guide_language: records.guide_language,
      guiding_method: records.guiding_method,
      max_altitude: records.max_altitude,
      location_start_from: records.location_start_from,
      location_end_to: records.location_end_to,
      age: records.age,
      google_map: records.google_map,
      overview: records.overview,
      highlights: records.highlights,
      includes: records.includes,
      excludes: records.excludes,
      good_to_know: records.good_to_know,
      cancellation: records.cancellation,
      accommodation: records.accommodation,
      duration: records.duration,
      discount_total_no: records.discount_total_no,
      discount_percentage: records.discount_percentage,
      is_sponsored: records.is_sponsored,
      status: records.status,
      full_payment: records.full_payment,
      meta_page_title: (records.meta_tag != null) ? records.meta_tag.title : '',
      meta_keywords: (records.meta_tag != null) ? records.meta_tag.keywords : '',
      meta_description: (records.meta_tag != null) ? records.meta_tag.description : '',
      position: records.position,
      available_features: formFeatures,
      tag: records.tag,
      no_follow: records.no_follow,
      no_index: records.no_index,
    };

    state.package_images = (records.images != null) ? records.images : [];
    state.form_dropdown = {
      region_id: {
        label: records.region_id.name,
        value: records.region_id.id
      },
      vendor_id: {
        label: records.vendor_id.full_name,
        value: records.vendor_id.id
      },
      activity_id: {
        label: records.activity_id.name,
        value: records.activity_id.id
      },
      departure_type: {
        label: records.departure_type.name,
        value: records.departure_type.id
      },
      package_type: {
        label: records.package_type.name,
        value: records.package_type.id
      },
      listing_type: {
        label: records.listing_type.name,
        value: records.listing_type.id
      },
      tag:  {
        label: records.tag,
        value: records.tag,
    },
    };
    state.itenaries.data = records.itenaries;
  },

  /**
   * Update Form.
   *
   * @param {Object} state
   * @param {*} param1
   */
  [UPDATE_FORM](state, {key, value}) {
    if (state.form_dropdown[key]) {
      state.form_dropdown[key] = value;
      state.form[key] = value.value;
      return;
    }

    if (key == 'available_features') {
      state.form[key] = [];
      for (var i = 0; i < value.length; i++) {
          state.form[key].push(value[i].value);
      }

      if (state.geo[key]) {
          state.geo[key] = value;
          return;
      }
    } else {
      if (state.geo[key]) {
        state.geo[key] = value;
        state.form[key] = value.value;
        return;
    }
      state.form[key] = value;
    }

    
  },

  /**
   * Update Homestay Rooms Form.
   *
   * @param {Object} state
   * @param {*} param1
   */
  [UPDATE_ITENARY_FORM](state, {key, value}) {
    state.itenaryForm[key] = value;
  },

  [CLEAR_ITENARY_FORM](state) {
    state.itenaryForm = {
      itenaryId: "",
      duration: "",
      accommodation: "",
      title: "",
      description: ""
    };
  },

  [SET_PACKAGE_ITENARY_FORM](state, records) {
    state.itenaryForm = {
      itenaryId: records.id,
      duration: records.duration,
      accommodation: records.accommodation,
      title: records.title,
      description: records.description
    };
  },

  [UPDATE_DEPARTURE_FORM](state, {key, value}) {
    state.departureForm[key] = value;
  },

  [SET_PACKAGE_DEPARTURES](state, records) {
    state.departures.data = records.data;
    state.departures.pagination.last_page = records.meta.last_page;
    state.departures.pagination.per_page = records.meta.per_page;
    state.departures.pagination.current_page = records.meta.current_page;
  },

  [SET_PACKAGE_DEPARTURE_FORM](state, records) {
    state.departureForm = {
      departure_date: records.departure_date,
      discount_percent: records.discount_percent,
      offer_title: records.offer_title,
      status: records.status,
    };
  },

  [CLEAR_DEPARTURE_FORM](state) {
    state.departureForm = {
      departure_date: "",
      discount_percent: 0,
      offer_title: "",
      status: 1,
    };
  },


  /**
   * Set Search Parameters.
   *
   * @param {Object} state
   * @param {*} param1
   */
  [SET_SEARCH_PARAMETERS](state, {
    key,
    value
  }) {
    state.search.query[key] = value;
  },
  /**
   * Clear Search Parameters.
   *
   * @param {Object} state
   * @param {*} param1
   */

  [CLEAR_SEARCH_PARAMETERS](state) {
    state.search.query.id = '';
    state.search.query.name = '';
  },
};
