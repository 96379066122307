export default {
    blogs: {
        data : [],
        pagination: {
            last_page: 1,
            per_page: 10,
            current_page: 1,
        },
    },
    packages: [],
    homestays: [],
    hotels: [],
    search: {
        query: {
            id: '',
            title: '',
            blog_category_id: '',
            status: '',
        },
    },
    form: {
        title: "",
        slug: "",
        short_description: "",
        description: "",
        blog_category_id: "",
        status: true,
        alt: '',
        packages: [],
        homestays: [],
        hotels: [],
        image: [],
        image_url: '',
        meta_page_title: '',
        meta_keywords: '',
        meta_description: '',
        position: "",
        no_follow: false,
        no_index: false,
    },
    blogCategories: [],
    dropdown:{
        blog_category_id:{}
    },
    
    blogImages:[],
    alt:[],
}