import {
  SET_HOMESTAYS,
  CLEAR_FORM,
  SET_HOMESTAY_FORM,
  UPDATE_FORM,
  SET_COUNTRIES,
  SET_AMENITIES,
  SET_USERS,
  UPDATE_ROOM_FORM,
  CLEAR_ROOM_FORM,
  SET_HOMESTAY_ROOM_FORM,
  SET_SEARCH_PARAMETERS,
  CLEAR_SEARCH_PARAMETERS,
  SET_HOMESTAY_REQUESTS,
} from "../mutation-types";

export default {
  /**
   * Set package regions.
   *
   * @param {Object} state
   * @param {Object} records
   */
  [SET_HOMESTAYS](state, records) {
    state.homestays.data = records.data;
    state.homestays.pagination.last_page = records.meta.last_page;
    state.homestays.pagination.per_page = records.meta.per_page;
    state.homestays.pagination.current_page = records.meta.current_page;
  },

  [SET_HOMESTAY_REQUESTS](state, records) {
    state.homestayRequests.data = records.data;
    state.homestayRequests.pagination.last_page = records.meta.last_page;
    state.homestayRequests.pagination.per_page = records.meta.per_page;
    state.homestayRequests.pagination.current_page = records.meta.current_page;
  },

  /**
   * Set Geo[countries, state and cities]
   *
   * @param {Object} state
   * @param {Object} records
   */
  [SET_COUNTRIES](state, records) {
    state.countries = records.data;
  },

  /**
   * Set Amenities
   *
   * @param {Object} state
   * @param {Object} records
   */
  [SET_AMENITIES](state, records) {
    state.geo.amenities = records;
  },

  /**
   * Set Amenities
   *
   * @param {Object} state
   * @param {Object} records
   */
  [SET_USERS](state, records) {
    state.users = records.data;
  },

  [CLEAR_FORM](state) {
    state.form = {
      homestayId: "",
      name: "",
      slug: "",
      user_id: "",
      email: "",
      website: "",
      price_per_person: "",
      total_person_capacity: "",
      is_sponsored: false,
      status: false,
      is_full_payment: false,
      order: "",
      discount_persons: "",
      discount: "",
      min_payment_amount: "",
      address_1: "",
      address_2: "",
      phone_1: "",
      phone_2: "",
      description: "",
      room_description: "",
      cancellation_description: "",
      local_tourist_attraction: "",
      available_facilities: "",
      available_local_infrastructure: "",
      what_is_included: "",
      amenities: [],
      images: [],
      alt: [],
      meta_page_title: "",
      meta_keywords: "",
      meta_description: "",
      latitude: "",
      longitude: "",
      position: "",
      google_map: "",
      available_features: [],
      room_type: "",
      property_type: "",
      tag: "",
      no_follow: false,
      no_index: false,
    };

    state.geo = {
      homestay_region_id: [],
      user_id: [],
      amenities: [],
      images: [],
      available_features: [],
    };

    state.homestayImages = [
      {
        image: "",
        alt: "",
        source: [
          {
            source: null,
            options: {
              type: "",
              file: "",
            },
          },
        ],
      },
    ];

    state.form_dropdown = {
      room_type: {},
      property_type: {},
      tag: {},
    };
  },

  [SET_HOMESTAY_FORM](state, records) {
    let setAmenities = [];
    let formAmenities = [];
    if (records.amenities) {
      records.amenities.forEach((am, index) => {
        setAmenities.push({ label: am.display_name, value: am.id });
        formAmenities.push(am.id);
      });
    }

    let setFeatures = [];
    let formFeatures = [];
    if (records.available_features) {
      records.available_features.forEach((am, index) => {
        setFeatures.push({ label: am.title, value: am.id });
        formFeatures.push(am.id);
      });
    }

    if (records.images) {
      state.editHomestayImages = [];
      records.images.forEach((img, index) => {
        state.editHomestayImages.push({
          source: img.url,
          alt: img.alt,
          imageId: img.id,
          featured: img.featured,
        });
      });
      state.image_preview = records.images[0].url;
    }
    state.form = {
      homestayId: records.id,
      name: records.name,
      slug: records.slug,
      user_id: records.user_id,
      email: records.email,
      website: records.website,
      price_per_person: records.price_per_person,
      total_person_capacity: records.total_capacity,
      is_sponsored: records.is_sponsored,
      homestay_region_id: records.region ? records.homestay_region_id : "",
      status: records.status,
      is_full_payment: records.full_payment,
      order: records.order,
      discount_persons: records.discount_persons,
      discount: records.discount,
      min_payment_amount: records.min_payment,
      address_1: records.address_1,
      address_2: records.address_2,
      phone_1: records.phone_1,
      phone_2: records.phone_2,
      description: records.homestay_details,
      room_description: records.room_details,
      cancellation_description: records.cancellation_details,
      local_tourist_attraction: records.local_tourist_attraction,
      available_facilities: records.available_facilities,
      available_local_infrastructure: records.available_local_infrastructure,
      what_is_included: records.what_is_included,
      images: records.images,
      meta_page_title: records.meta_tag ? records.meta_tag.title : "",
      meta_keywords: records.meta_tag ? records.meta_tag.keywords : "",
      meta_description: records.meta_tag ? records.meta_tag.description : "",
      latitude: records.latitude,
      longitude: records.longitude,
      amenities: formAmenities,
      position: records.position,
      google_map: records.google_map,
      available_features: formFeatures,
      room_type: records.room_type.id,
      property_type: records.property_type.id,
      tag: records.tag,
      no_follow: records.no_follow,
      no_index: records.no_index,
    };

    state.rooms.data = records.rooms;

    state.geo = {
      user_id: {
        label: records.user_id.full_name,
        value: records.user_id.id,
      },
      amenities: setAmenities,
      homestay_region_id: {
        label: records.region.name,
        value: records.region.id,
      },
      images: records.images,
      available_features: setFeatures,
    };

    state.form_dropdown = {
      room_type: {
        label: records.room_type.name,
        value: records.room_type.id,
      },
      property_type: {
        label: records.property_type.name,
        value: records.property_type.id,
      },
      tag: {
        label: records.tag,
        value: records.tag,
      },
    };
  },

  /**
   * Update Homestay Form.
   *
   * @param {Object} state
   * @param {*} param1
   */
  [UPDATE_FORM](state, { key, value }) {
    if (state.form_dropdown[key]) {
      state.form_dropdown[key] = value;
      state.form[key] = value.value;
      return;
    }

    if (key === "amenities") {
      state.form[key] = [];
      for (var i = 0; i < value.length; i++) {
        state.form[key].push(value[i].value);
      }

      if (state.geo[key]) {
        state.geo[key] = value;
        return;
      }
    } else if (key === "images") {
      if (state.form.images[value.index]) {
        state.form.images[value.index] = value.value;
      } else {
        state.form.images.push(value.value);
      }
      state.homestayImages[value.index].image = value.value;
    } else if (key === "alt") {
      if (state.form.alt[value.index]) {
        state.form.alt[value.index] = value.value;
      } else {
        state.form.alt.push(value.value);
      }
      state.homestayImages[value.index].alt = value.value;
    } else if (key.key === "addImage") {
      state.homestayImages.push(value);
    } else if (key.key === "RemoveImage") {
      state.homestayImages.splice(key.index, 1);
    } else if (key === "available_features") {
      state.form[key] = [];
      for (var i = 0; i < value.length; i++) {
        state.form[key].push(value[i].value);
      }

      if (state.geo[key]) {
        state.geo[key] = value;
        return;
      }
    } else {
      if (state.geo[key]) {
        state.geo[key] = value;
        state.form[key] = value.value;
        return;
      }
      state.form[key] = value;
    }
  },
  /**
   * Set Search Parameters.
   *
   * @param {Object} state
   * @param {*} param1
   */
  [SET_SEARCH_PARAMETERS](state, { key, value }) {
    state.search.query[key] = value;
  },
  /**
   * Clear Search Parameters.
   *
   * @param {Object} state
   * @param {*} param1
   */

  [CLEAR_SEARCH_PARAMETERS](state) {
    state.search.query.id = "";
    state.search.query.name = "";
    state.search.query.email = "";
  },

  /**
   * Update Homestay Rooms Form.
   *
   * @param {Object} state
   * @param {*} param1
   */
  [UPDATE_ROOM_FORM](state, { key, value }) {
    if (key == "amenities") {
      state.roomForm[key] = [];
      for (var i = 0; i < value.length; i++) {
        state.roomForm[key].push(value[i].value);
      }

      if (state.roomsMultipleData[key]) {
        state.roomsMultipleData[key] = value;
        return;
      }
    } else if (key == "images") {
      if (state.roomForm.images[value.index]) {
        state.roomForm.images[value.index] = value.value;
      } else {
        state.roomForm.images.push(value.value);
      }
      state.roomImages[value.index].image = value.value;
    } else if (key == "alt") {
      if (state.roomForm.alt[value.index]) {
        state.roomForm.alt[value.index] = value.value;
      } else {
        state.roomForm.alt.push(value.value);
      }
      state.roomImages[value.index].alt = value.value;
    } else if (key.key == "addImage") {
      state.roomImages.push(value);
    } else if (key.key == "RemoveImage") {
      state.roomImages.splice(key.index, 1);
    } else {
      if (state.roomsMultipleData[key]) {
        state.roomsMultipleData[key] = value;
        state.roomForm[key] = value.value;
        return;
      }
      state.roomForm[key] = value;
    }
  },

  [CLEAR_ROOM_FORM](state) {
    state.roomForm = {
      roomId: "",
      name: "",
      rooms_id: "",
      rooms_type: "",
      price: "",
      max_adult: "",
      max_child: "",
      beds: "",
      room_area: "",
      bathroom_area: "",
      extra_charge: "",
      status: "",
      description: "",
      alt: [],
      images: [],
      amenities: [],
    };

    state.roomsMultipleData = {
      amenities: [],
      images: [],
      alt: [],
    };

    state.roomImages = [
      {
        image: "",
        alt: "",
        source: [
          {
            source: null,
            options: {
              type: "",
              file: "",
            },
          },
        ],
      },
    ];
  },

  [SET_HOMESTAY_ROOM_FORM](state, records) {
    let setRoomAmenities = [];
    let roomAmenities = [];
    if (records.amenities) {
      records.amenities.forEach((am, index) => {
        setRoomAmenities.push({ label: am.display_name, value: am.id });
        roomAmenities.push(am.id);
      });
    }
    if (records.images) {
      state.editRoomImages = [];
      records.images.forEach((img, index) => {
        state.editRoomImages.push({
          source: img.url,
          alt: img.alt,
          imageId: img.id,
          featured: img.featured,
        });
      });
    }
    state.roomForm = {
      roomId: records.id,
      name: records.name,
      rooms_id: records.rooms_id,
      rooms_type: records.rooms_type,
      price: records.price,
      max_adult: records.max_adult,
      max_child: records.max_child,
      beds: records.beds,
      room_area: records.room_area,
      bathroom_area: records.bathroom_area,
      extra_charge: records.extra_charge,
      status: records.status,
      description: records.details,
      images: records.images,
      amenities: roomAmenities,
    };

    state.roomsMultipleData = {
      amenities: setRoomAmenities,
      images: setRoomAmenities,
    };
  },
};
